@import '~bootstrap/scss/bootstrap.scss';

html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

#root {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

